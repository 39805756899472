body.viewer {
  background-color: rgba(44, 48, 50, 0.05);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  max-width: 100vw;
  overflow: hidden;
}

#deckgl-wrapper #deckgl-overlay {
  z-index: 1;
}

#deckgl-wrapper #view-default-view > div {
  z-index: auto !important;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 0;
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex {
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .home {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.home--embed {
  flex-direction: column;
} */

/* .footer {
  display: flex;
  align-items: center;
  background-color: rgba(44, 48, 50, 0.05);
  height: 22px;
  padding: 0 8px 8px;
}

.footer__text {
  font-size: 12px;
  line-height: 16px;
}

.footer__text a {
  color: #1785fb;
}

.footer__text img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  vertical-align: middle;
}

.footer__logo {
  margin-left: auto;
}

.footer__logo img {
  display: block;
  height: 18px;
} */

/* #deckgl-wrapper {
  overflow: hidden;
  border-radius: 4px;
} */

/* .editor {
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 2;
} */

/* .home .ctrl-buttons {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
} */

/* .sidebar-container {
  max-width: 100vw;
  display: flex;
} */

/* .sidebar {
  height: 100%;
  width: 56px;
  background-color: #036FE2;
} */

/* .sidebar__element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin: 16px auto;
  border-radius: 4px;
  cursor: pointer;
} */

/* .sidebar__element--logo {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: default;
} */

/* .sidebar__element.is-selected {
  background-color: rgba(255, 255, 255, 0.12);
}

.sidebar__element:hover {
  background-color: rgba(255, 255, 255, 0.12);
} */

/* .sidebar__element--logo:hover {
  background-color: unset;
} */

.information-sidebar {
  display: flex;
  flex-direction: column;
  width: 480px;
  overflow: hidden;
  background-color: white;
}

.configuration-sidebar {
  display: flex;
  flex-direction: column;
  width: 504px;
  overflow: hidden;
  background-color: white;
}

.configuration-sidebar__title {
  position: relative;
  padding: 20px 24px;
  border-bottom: 1px solid rgba(44, 48, 50, 0.05);
}

.configuration-sidebar__title .close-button {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.configuration-sidebar__title h2 {
  font-size: 24px;
}

.configuration-sidebar__section {
  border-bottom: 1px solid rgba(44, 48, 50, 0.05);
}

.configuration-sidebar__section .section-title {
  position: relative;
  padding: 8px 24px;
}

.configuration-sidebar__section .section-title:after {
  content: '';
  position: absolute;
  top: 0;
  right: 14px;
  height: 100%;
  width: 12px;
  background-image: url('icons/expand-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}

.section-content textarea {
  background-color: rgba(44, 48, 50, 0.05);
  border: 8px solid transparent;
  border-radius: 4px;
  padding: 0px 8px;
}

.section-content textarea::-webkit-scrollbar {
  display: none;
}

.configuration-sidebar__section.open .section-title:after {
  transform: rotate(0deg);
}

.configuration-sidebar__section .section-title:hover {
  background-color: rgba(44, 48, 50, 0.05);
  cursor: pointer;
}

.configuration-sidebar__section .section-content {
  box-sizing: border-box;
  overflow: hidden;
  height: 0;
}

.configuration-sidebar__section.open .section-content {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 8px 0 0;
}

.configuration-sidebar__section.open .section-content.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.configuration-sidebar__section.open .section-content textarea {
  width: 100%;
  height: 128px;
}

.configuration-sidebar__section--editor {
  flex-grow: 1;
  height: 100%;
}

.configuration-sidebar__section--editor.open .section-content,
.configuration-sidebar__section--editor.open .json-form {
  height: 100%;
}

.configuration-sidebar__section h3 {
  font-size: 14px;
}

.section-content__group {
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 14px;
}

.section-content__group input {
  height: 20px;
  width: 20px;
  margin: 0 16px 0 0;
}

.section-content__selector {
  display: flex;
  align-items: center;
  padding: 8px 0 0 48px;
  font-size: 14px;
  color: rgba(44, 48, 50, 0.6);
}

.section-content__selector select {
  flex-grow: 1;
  margin-left: 24px;
  padding: 0 16px;
  height: 36px;
  background-color: rgba(44, 48, 50, 0.05);
  background-image: url('icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: right 16px center;
  border: unset;
  border-radius: 4px;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* .button-container {
  width: 100%;
  text-align: right;
  margin-top: 16px;
} */

/* .button {
  padding: 6px 24px;
  color: #036fe2;
  font-size: 14px;
  line-height: 24px;
  border: 2px solid #036fe2;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
} */

/* .deck-gl-map-container {
  position: relative;
  width: 100%;
  height: 100%;
} */

/* .zoom-control {
  position: absolute;
  left: 16px;
  bottom: 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(44, 48, 50, 0.2);
}

.zoom-option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  height: 24px;
  width: 24px;
  border-bottom: 1px solid rgba(44, 48, 50, 0.12);
  cursor: pointer;
  user-select: none;
}

.zoom-option > * {
  pointer-events: none;
}

.zoom-option:hover {
  background-color: rgba(44, 48, 50, 0.05);
} */
